






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class GridColumnComponent extends Vue {
  @Prop({
    default: 1,
    type: Number
  }) readonly size!: number
}
