









import { Component, Prop, Vue } from 'vue-property-decorator'

import SkillConstant from '../interfaces/skill-constant'

@Component
export default class SkillsListComponent extends Vue {
  @Prop({
    required: true,
    type: Object
  }) readonly skill!: SkillConstant;
}
