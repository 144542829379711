import ApplicationConstants from '@/interfaces/application-constants'

import experienceConstant from './experience.constant'
import skillsConstant from './skills.constant'

const applicationConstants: ApplicationConstants = {
  email: 'patrick@pateason.com',
  experience: experienceConstant,
  linkedinUrl: 'https://www.linkedin.com/in/pateason`',
  githubUrl: 'https://github.com/coastalp47',
  skills: skillsConstant,
  twitterUrl: 'https://twitter.com/pateasonio'
}

export default applicationConstants
