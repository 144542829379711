import ExperienceConstant from '../interfaces/experience-constant'

const aspenwareExperience: ExperienceConstant = {
  employer: 'Aspenware',
  location: 'Denver, CO',
  position: 'Senior Frontend Developer',
  responsibilities: [],
  startDate: new Date(2021, 2, 1)
}

const homegaugeExperience: ExperienceConstant = {
  employer: 'HomeGauge',
  endDate: new Date(2021, 1, 19),
  location: 'Asheville, NC',
  position: 'Frontend Developer',
  responsibilities: [
    'Frontend application development using Vue and Angular',
    'Microfrontend application development using single-spa and Vue',
    'Angular Universal SSR server development',
    'Scoped NPM library development and internal distribution using Artifactory and Gitlab',
    'CI/D shared, multi-repo pipeline development and management using Gitlab'
  ],
  startDate: new Date(2019, 9, 1)
}

const gpmExperience: ExperienceConstant = {
  employer: 'GPM Corp',
  endDate: new Date(2019, 9, 1),
  location: 'Asheville, NC',
  position: 'Lead Software Developer',
  responsibilities: [
    'Develop and maintain CareTeam; a facility-based platform for patient care built with .net Core 2.1, Entity Framework, Vue, and Vuex',
    'Spearhead and develop CareNote; a HIPAA compliant real-time messaging application between multiple sources built with.net Core 2.1, Entity Framework, Vue, Vuex, and SignalR.',
    'PCI compliant automated payment processing system using Stripe and Azure Functions',
    'Manage development delegation and workflow for CareTeam developers'
  ],
  startDate: new Date(2018, 6, 1)
}

const missionHealthExperience: ExperienceConstant = {
  employer: 'Mission Health System',
  endDate: new Date(2018, 6, 1),
  location: 'Asheville, NC',
  position: 'Web Development Engineer',
  responsibilities: [
    'Frontend development using React and Redux',
    'Application development with React Native and Apple HealthKit',
    'Server application development using .net Framework and .net Core',
    'HL7 stream consumption parsing using Mirth and Azure FHIR services',
    'CI/D pipelines using Azure DevOps',
    'Docker infrastructure management and development using Azure'
  ],
  startDate: new Date(2017, 4, 1)
}

const wideOpenExperience: ExperienceConstant = {
  employer: 'Wide Open Technologies',
  endDate: new Date(2017, 4, 1),
  location: 'Asheville, NC',
  position: 'Software Developer',
  responsibilities: [
    'PHP application development using Yii2',
    'Ecommerce development using Magento and Shopify',
    'Realtime Node application development using ExpressJS, SailsJS and sockets.io',
    'Frontend development using React and Angular 1.x',
    'Infrastructure management and development using Amazon Web Services: EC2, ELB, ASG, RDS, S3, EFS, Lambda, ElasticSearch',
    'PCI compliant automated payment processing system using Stripe and AWS Lambda',
    'Proof of concept mobile application development with React Native'
  ],
  startDate: new Date(2014, 10, 1)
}

const meshExperience: ExperienceConstant = {
  employer: 'MESH Design & Development',
  endDate: new Date(2014, 10, 1),
  location: 'Charleston, WV',
  position: 'Web Developer',
  responsibilities: [
    'Frontend web development using WordPress'
  ],
  startDate: new Date(2013, 9, 1)
}

const rainmakerExperience: ExperienceConstant = {
  employer: 'Rainmaker Inc',
  endDate: new Date(2013, 3, 1),
  location: 'Charleston, WV',
  position: 'User Experience Designer',
  responsibilities: [
    'Design and front-end development using WordPress, ExpressionEngine, and NationBuilder',
    'Post-production and compositing for broadcast using After Effects, Final Cut Pro, and Apple Motion',
    '3D and 2D animation for compositing in broadcast using After Effects and Maya'
  ],
  startDate: new Date(2012, 4, 1)
}

const blackbaudExperience: ExperienceConstant = {
  employer: 'Blackbaud',
  endDate: new Date(2013, 3, 1),
  location: 'Charleston, SC',
  position: 'Interactive Designer',
  responsibilities: [
    'Frontend web design and development using NetCommunity and Wordpress platforms'
  ],
  startDate: new Date(2012, 4, 1)
}

const experienceConstant: ExperienceConstant[] = [
  aspenwareExperience,
  homegaugeExperience,
  gpmExperience,
  missionHealthExperience,
  wideOpenExperience
]

export default experienceConstant
