












import { Component, Vue } from 'vue-property-decorator'

import LogoMark from './LogoMark.vue'

@Component({
  components: {
    LogoMark
  }
})
export default class HeaderComponent extends Vue {}
