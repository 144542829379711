






















import { Component, Vue } from 'vue-property-decorator'
import ApplicationConstants from '../constants/application.constant'

console.log(ApplicationConstants)

@Component
export default class FooterComponent extends Vue {
  githubUrl = ApplicationConstants.githubUrl

  linkedinUrl = ApplicationConstants.linkedinUrl

  twitterUrl = ApplicationConstants.twitterUrl
}
