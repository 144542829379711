










































import ExperienceConstant from '@/interfaces/experience-constant'
import { Component, Prop, Vue } from 'vue-property-decorator'

import GridColumn from './GridColumn.vue'
import GridRow from './GridRow.vue'
import SectionContainer from './SectionContainer.vue'

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December']

@Component({
  components: {
    GridColumn,
    GridRow,
    SectionContainer
  }
})
export default class ExperienceComponent extends Vue {
  @Prop({
    default: () => [],
    type: Array
  }) private experience!: ExperienceConstant[];

  public selectedExperience = 0;

  formatDate (date: Date): string {
    return date ? `${monthNames[date.getMonth()]} ${date.getFullYear()}` : ''
  }
}
