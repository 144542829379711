import SkillsConstants from '@/interfaces/skills-constant'

const skillsConstant: SkillsConstants = {
  frameworks: {
    skills: [
      '.net Core',
      'SignalR',
      'Entity Framework',
      'Spring Boot',
      'Vue',
      'React',
      'React Native',
      'Angular',
      'Yii2',
      'Webpack'
    ],
    title: 'Frameworks'
  },
  languages: {
    skills: [
      'C#',
      'JavaScript',
      'TypeScript',
      'Java',
      'PHP',
      'SQL'
    ],
    title: 'Languages'
  },
  services: {
    skills: [
      'Azure',
      'AWS',
      'Azure Serverless Functions',
      'Azure Cognitive Services',
      'Azure DevOps',
      'Stripe',
      'Docker'
    ],
    title: 'Services'
  }
}

export default skillsConstant
