




































import { Component, Vue } from 'vue-property-decorator'

import SectionContainer from './SectionContainer.vue'

@Component({
  components: {
    SectionContainer
  }
})
export default class Intro extends Vue {}
