

















import { Component, Prop, Vue } from 'vue-property-decorator'

import GridColumn from './GridColumn.vue'
import GridRow from './GridRow.vue'
import SectionContainer from './SectionContainer.vue'
import SkillsList from './SkillsList.vue'
import SkillsConstant from '../interfaces/skills-constant'

@Component({
  components: {
    GridColumn,
    GridRow,
    SectionContainer,
    SkillsList
  }
})
export default class SkillsComponent extends Vue {
  @Prop({
    default: [],
    required: true
  }) private skills!: SkillsConstant;
}
