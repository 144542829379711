











import { Component, Vue } from 'vue-property-decorator'

import Experience from './components/Experience.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import Intro from './components/Intro.vue'
import Skills from './components/Skills.vue'
import ApplicationConstant from './constants/application.constant'

@Component({
  components: {
    Experience,
    Footer,
    Header,
    Intro,
    Skills
  }
})
export default class App extends Vue {
  public readonly applicationConstant = ApplicationConstant;
}
